<template>
  <div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div class="card p-2">
          <div class="d-flex align-items-center">
            <img
              src="https://thailotto.com/assets/themes/front2021/images/thai_lottery_l.png"
              alt="s"
              height="50"
            >
            <h3
              class="font-weight-bolder"
              style="margin-left: 5px"
            >
              แทงหวย รัฐบาล เหลือเวลาอีก 00 วัน 00.00.00 ชม.
            </h3>
          </div>
          <hr>

          <div class="d-flex align-items-center justify-content-around">
            <button
              class="button-lot"
              :class="{active: isActive1}"
              role="button"
              @click="SelectDigit(2)"
            >
              หวย 2 ตัว
            </button>
            <button
              class="button-lot"
              :class="{active: isActive2}"
              @click="isActive2 = !isActive2"
            >
              หวย 3 ตัว
            </button>
            <button
              class="button-lot"
              :class="{active: isActive3}"
              @click="isActive3 = !isActive3"
            >
              หวย 6 กลับ
            </button>
            <button
              class="button-lot"
              :class="{active: isActive4}"
              @click="isActive4 = !isActive4"
            >
              19 ประตู
            </button>
            <button
              class="button-lot"
              :class="{active: isActive5}"
              @click="isActive5 = !isActive5"
            >
              เลขวิ่ง
            </button>
          </div>

          <div class="card-numlot mt-2">

            <!-- <b-badge
              v-for="items in 120"
              :key="items"
              variant="primary"
              style="margin: 1-px"
            >
              89
            </b-badge> -->
            <b-badge
              variant="primary"
              style="margin: 1-px"
            >
              {{ NumBet }}
            </b-badge>
          </div>

          <div>
            <div class="row mt-2">
              <div class="col-md-4">
                <label for="number font-weight-bolder">ใส่ตัวเลข</label>
                <input
                  id="InputNum"
                  v-model="NumArrCartInput"
                  type="number"
                  class="form-control"
                  placeholder="ใส่ตัวเลข"
                  @input="AddCartInput(NumArrCartInput)"
                >
              </div>
              <div class="col-md-4">
                <label for="number font-weight-bolder">ราคาบน</label>
                <input
                  id="number"
                  type="number"
                  class="form-control"
                  placeholder="ใส่ราคาบน"
                >
              </div>
              <div class="col-md-4">
                <label for="number font-weight-bolder">ราคาล่าง</label>
                <input
                  id="number"
                  type="number"
                  class="form-control"
                  placeholder="ใส่ราคาล่าง"
                >
              </div>
              <div class="col-md-4" />
              <div class="col-md-4 mt-1">
                <button class="btn btn-warning btn-block rounded">
                  ยืนยัน
                </button>
              </div>
              <div class="col-md-4" />

            </div>
          </div>
          <div>
            <hr>
            <table width="100%">
              <colgroup>
                <col width="20%">
                <col width="70%">
                <col width="10%">
              </colgroup>
              <tbody>
                <tr>
                  <td
                    colspan="1"
                    class="text-center"
                  >
                    <label class="h3">
                      3 ตัว <br>
                      <span class="text-danger">บน x โต๊ด</span><br>
                      1 x 0<br>
                    </label>
                  </td>
                  <td colspan="1">
                    <div class="card-sum text-center">
                      <b-badge
                        v-for="itemsx in 120"
                        :key="itemsx"
                        variant="primary"
                        class="bg-aqua"
                      >
                        898
                      </b-badge>
                    </div>
                  </td>
                  <td
                    colspan="1"
                    class="text-center"
                  >
                    <button
                      class="btn-danger rounded"
                      style="width: 35px !important; height: 35px !important"
                      @click="tada"
                    >
                      <i class="fas fa-trash-alt" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center">
            <hr>
            <div class="mt-2">
              <label>ใส่ชื่อลูกค้า : </label>
              <div class="form-group-100 form-group has-feedback">
                <div class="input-2-box">
                  <input
                    v-model="name"
                    type="text"
                    name="name"
                    autocomplete="off"
                    class="form-control"
                    data-fv-field="name"
                  ><i
                    class="form-control-feedback"
                    data-fv-icon-for="name"
                    style="display: none;"
                  />
                  <div class="text-control">
                    ฿20000
                  </div>
                </div>
              </div>
            </div>
            <button
              class="btn btn-success rounded"
              @click="submit"
            >
              บันทึกข้อมูล
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <b-card no-body>
          <h3 class="font-weight-bolder p-2">
            รายการแทง {{ countArry }} 0 รายการ
          </h3>
          <template>
            <validation-observer ref="BetItemsRules">
              <b-table
                responsive
                head-variant="light"
                :fields="fields"
                :items="items"
                class="mb-4 text-center"
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{
                      width: field.key === 'foo' ? '120px' : '180px',
                    }"
                  >
                </template>
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(price)="data">
                  <validation-provider
                    #default="{ errors }"
                    :rules="`required|between:${
                      CheckAmount(data.item.num_type).Min
                    },${CheckAmount(data.item.num_type).Max}`"
                    name="Number between"
                  >
                    <b-form-input
                      v-model="data.item.price"
                      type="number"
                      class="form-css text-center"
                      placeholder=""
                      @change="
                        CheckAmount(
                          data.item.num_type,
                          data.item.price,
                          data.index,
                          data.item.num_lot,
                        )
                      "
                    />
                    <!-- @change="CheckAmount(data.item.num_type,data.item.price,data.index)" -->
                    <small class="text-danger">{{
                      errors[0] ||
                        TestPrice(
                          data.item.price,
                          CheckAmount(data.item.num_type).Min,
                          CheckAmount(data.item.num_type).Max,
                        ) === true
                        ? `ระบุจำนวนเงิน ${Commas(
                          CheckAmount(data.item.num_type).Min,
                        )}-${Commas(
                          CheckAmount(data.item.num_type).Max,
                        )} บาท`
                        : ''
                    }}</small>
                  </validation-provider>
                </template>

                <template #cell(win_rate)="data">
                  <p
                    :style="`color: ${
                      data.item.RateLimit ? 'red' : 'black'
                    }`"
                    class="mt-1"
                  >
                    {{ data.item.win_rate }}
                  </p>
                </template>

                <template #cell(win)="data">
                  {{ Commas(data.item.win_rate * data.item.price) }}
                </template>

                <template #cell(delete)="data">
                  <!-- {{ data.index }} -->
                  <b-button
                    variant="danger"
                    size="sm"
                    class="btn-icon"
                    style="height: 32px !important; width: 32px !important;"
                    @click="items.splice(data.index, 1) , countArry = countArry - 1"
                  >
                    <i class="fas fa-trash-alt" />
                  </b-button>
                </template>
              </b-table>
            </validation-observer>
          </template>
        </b-card>

        <div class="card">
          <div class="p-2">
            <h3 class="font-weight-bolder">
              รายการแทงล่าสุด : --
            </h3>
          </div>
          <table class="table">
            <colgroup>
              <col width="5%">
              <col width="20%">
              <col width="20%">
              <col width="20%">
              <col width="10%">
              <col width="15%">
              <col width="10%">
            </colgroup>
            <thead>
              <tr>
                <th>#</th>
                <th>เวลา</th>
                <th>ตลาด</th>
                <th>อ้างอิง</th>
                <th>รายการ</th>
                <th>ราคา</th>
                <th>ลบ</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  v-if="!isShow"
                  colspan="1"
                  class="text-center"
                >
                  1
                </td>
                <td colspan="1">
                  -
                </td>
                <td colspan="1">
                  -
                </td>
                <td colspan="1">
                  -
                </td>
                <td colspan="1">
                  -
                </td>
                <td colspan="1">
                  -
                </td>
                <td
                  colspan="1"
                  class="text-center"
                >
                  <button
                    class="btn-danger rounded"
                    style="width: 35px !important; height: 35px !important"
                    @click="tada"
                  >
                    <i class="fas fa-trash-alt" />
                  </button>
                </td>

                <td
                  v-if="isShow"
                  colspan="7"
                  class="text-center"
                >
                  <router-link :to="{name : 'list-hauy'}">
                    ดูบิลเพิ่มเติม
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BBadge, BCard, BTable, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'animate.css'
import momenttz from 'moment-timezone'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'BuyHuay',
  components: {
    BBadge,
    BCard,
    BTable,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isActive1: false,
      isActive2: false,
      isActive3: false,
      isActive4: false,
      isActive5: false,
      // fields: [
      //   // { key: 'index', label: 'ลำดับ' },
      //   { key: 'num_lot', label: 'เลข' },
      //   { key: 'num_type', label: 'ประเภท' },
      //   { key: 'price', label: 'ยอด', thStyle: { width: '40%' } },
      //   { key: 'win_rate', label: 'จ่าย' },
      //   { key: 'win', label: 'ชนะ' },
      //   { key: 'delete', label: 'ลบ', thStyle: { width: '5%' } },
      // ],
      isActive: false,
      BetType3: '',
      add_remark: '',
      remark: null,
      discount_member: 0,
      discount: null,
      UserData: JSON.parse(localStorage.getItem('userData')),
      optionsMember: '',
      member: '',
      rule: null,
      confirm: false,
      countArry: 0,
      required: true,
      between: true,
      digits: true,
      payOut: false,
      CheckSubmit: true,
      BugOtp: true,
      show: false,
      ShowLimit: false,
      ShowClose: false,
      fieldsModal: [
        { key: 'type', label: 'ประเภท' },
        { key: 'NumBet', label: 'เลขที่แทง' },
      ],
      // LottoList: JSON.parse(localStorage.getItem('LottoList')),
      LottoList: [],
      LottoHeadList: [],
      LottoSubList: [],
      SumAmount: 0,
      AmountBet: 1,
      NumBet: '',
      NumArrCart: '',
      NumArrCartInput: null,
      otp: '',
      Status: 'Diposit',
      Clicked: 2,
      ShowDigit: 3,
      CheckBetType: true,
      maxlength: 3,
      // BetType: ['3ตัวบน', 'กลับตัวเลข'],
      BetType: [],
      Interval: null,
      IntervalRate: null,
      AgentData: null,
      fields: [
        // { key: 'index', label: 'ลำดับ' },
        { key: 'num_lot', label: 'เลข' },
        { key: 'num_type', label: 'ประเภท' },
        { key: 'price', label: 'ยอด', thStyle: { width: '40%' } },
        { key: 'win_rate', label: 'จ่าย' },
        { key: 'win', label: 'ชนะ' },
        { key: 'delete', label: 'ลบ', thStyle: { width: '5%' } },
      ],
      fieldss: [
        // { key: 'index', label: 'ลำดับ' },
        { key: 'num_lot', label: 'เลข' },
        { key: 'num_type', label: 'ประเภท' },
        { key: 'price', label: 'ยอด', thStyle: { width: '40%' } },
        { key: 'win_rate', label: 'จ่าย' },
        { key: 'win', label: 'ชนะ' },
      ],
      items: [],
      Historyitems: [],
      RateAll: {
        Three: {
          Up: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down_f: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down_b: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Tot: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
        },
        Two: {
          Up: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Tot: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
        },
        One: {
          Up: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
        },
      },
      MinAmount: 0,
      MaxAmount: 0,
      Title: {
        RoundID: '',
        LottoName: '',
        LottoHead: null,
        LottoSubHead: null,
        LottoDetail: [],
        CloseBet: '',
        CloseDate: '',
        CloseDateName: '',
        ShowCloseDate: '',
        LimitU3: [],
        LimitD3: [],
        LimitB3: [],
        LimitT3: [],
        LimitU2: [],
        LimitD2: [],
        LimitT2: [],
        LimitU1: [],
        LimitD1: [],
        CloseBetU3: [],
        CloseBetD3: [],
        CloseBetB3: [],
        CloseBetT3: [],
        CloseBetU2: [],
        CloseBetD2: [],
        CloseBetT2: [],
        CloseBetU1: [],
        CloseBetD1: [],
      },
      CloseBet: {
        CloseU3: [],
        CloseD3: [],
        CloseB3: [],
        CloseT3: [],
        CloseU2: [],
        CloseD2: [],
        CloseT2: [],
        CloseU1: [],
        CloseD1: [],
      },
      CloseBetModal: [],
      Slip: {
        SumAmount: null,
        date: null,
        time: null,
        listID: null,
        count: null,
        LottoHeadName: null,
      },
      statusCheck: false,
      Num_Arr: null,
      userbalance: 0,
      NameShow: '',
    }
  },
  beforeDestroy() {
    clearInterval(this.Interval)
  },
  created() {
    // this.Interval = setInterval(() => {
    //   this.ShowWallet()
    // }, 30000)
  },
  async mounted() {
    // this.getUserslist()
    // this.Getlottolist()
    // this.GetlottolistFix()
    // this.ShowAgent()
    // this.ShowWallet()
  },
  methods: {
    async CheckDiscount(dis) {
      // console.log(dis)
      if (Number(dis) > Number(this.discount)) {
        this.discount_member = this.discount
        this.$swal({
          title: 'แจ้งเตือน',
          text: `ไม่สามารถใช้ส่วนลดได้เกิน ${this.discount} %`,
          icon: 'warning',
          confirmButtonText: 'ตกลง',
        })
      }
    },
    async AddCartInput(num) {
      if (num.length > 3) {
        this.AddCart(num)
      } else if (this.ShowDigit === 3 && num.length === 3) {
        this.NumBet = num
        await this.PushDigit3()
        this.NumArrCartInput = null
        this.setFocusToTextBox()
      } else if (this.ShowDigit === 2 && num.length === 2) {
        this.NumBet = num
        await this.PushDigit2()
        this.NumArrCartInput = null
        this.setFocusToTextBox()
      } else if (this.ShowDigit === 1 && num.length === 1) {
        this.NumBet = num
        await this.PushDigit1()
        this.NumArrCartInput = null
        this.setFocusToTextBox()
      }
    },
    async setFocusToTextBoxCopy() {
      document.getElementById('InputNumCopy').focus()
    },
    async setFocusToTextBox() {
      document.getElementById('InputNum').focus()
    },
    async AddCart(num) {
      this.Num_Arr = null
      this.Num_Arr = num.split(' ')
      // console.log(this.Num_Arr)
      // eslint-disable-next-line no-restricted-syntax
      for await (const item of this.Num_Arr) {
        this.NumBet = item
        if (this.ShowDigit === 3) {
          if (this.NumBet.length === 3) {
            await this.PushDigit3()
            this.setFocusToTextBox()
          }
        } else if (this.ShowDigit === 2) {
          if (this.NumBet.length === 2) {
            await this.PushDigit2()
            this.setFocusToTextBox()
          }
        } else if (this.ShowDigit === 1) {
          if (this.NumBet.length === 1) {
            await this.PushDigit1()
            this.setFocusToTextBox()
          }
        }
      }
      this.NumArrCartInput = null
    },
    async GetDataLotto(check) {
      this.NameShow = check.LottoName
      this.Title.LottoHead = check.LottoHead
      this.Title.LottoSubHead = check.LottoSubHead
      await this.ShowTitle()
      await this.GetMaxMin()
      await this.GetRate(this.Title.RoundID)
    },
    Getlottolist() {
      this.$http
        .post('https://api.rmtlotto.com/api/member/LottoList/RoundListOpen')
        .then(response => {
          this.LottoHeadList = response.data
          // this.LottoSubList = response.data.SubHead
        })
        .catch(error => console.log(error))
    },
    GetlottolistFix() {
      this.$http
        .get('https://api.rmtlotto.com/api/member/LottoList/BuynowFix')
        .then(response => {
          this.LottoList = response.data
        })
        .catch(error => console.log(error))
    },
    getUserslist() {
      this.$http
        .get('https://api.rmtlotto.com/api/member/users/list')
        .then(response => {
          this.show = false
          this.optionsMember = response.data
        })
        .catch(error => console.log(error))
    },
    showRule(data) {
      if (data === 'หวยรัฐบาล') {
        this.rule = 'หวยรัฐบาล'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยออมสิน') {
        this.rule = 'หวยออมสิน'
        this.$refs['my-rule'].show()
      } else if (data === 'หวย ธกส') {
        this.rule = 'หวย ธกส'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยหุ้นไทย (เช้า)') {
        this.rule = 'หวยหุ้นไทย'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยหุ้นไทย (เที่ยง)') {
        this.rule = 'หวยหุ้นไทย'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยหุ้นไทย (บ่าย)') {
        this.rule = 'หวยหุ้นไทย'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยหุ้นไทย (เย็น)') {
        this.rule = 'หวยหุ้นไทย'
        this.$refs['my-rule'].show()
      } else if (data === 'เยอรมัน') {
        this.rule = 'เยอรมัน'
        this.$refs['my-rule'].show()
      } else if (data === 'อังกฤษ') {
        this.rule = 'อังกฤษ'
        this.$refs['my-rule'].show()
      } else if (data === 'ลาว') {
        this.rule = 'ลาว'
        this.$refs['my-rule'].show()
      } else if (data === 'หุ้นนิเคอิ (เช้า)') {
        this.rule = 'หุ้นนิเคอิ'
        this.$refs['my-rule'].show()
      } else if (data === 'หุ้นนิเคอิ (บ่าย)') {
        this.rule = 'หุ้นนิเคอิ'
        this.$refs['my-rule'].show()
      } else if (data === 'สิงคโปร์') {
        this.rule = 'สิงคโปร์'
        this.$refs['my-rule'].show()
      } else if (data === 'ไต้หวัน') {
        this.rule = 'ไต้หวัน'
        this.$refs['my-rule'].show()
      } else if (data === ' จีน (เช้า)') {
        this.rule = 'จีน'
        this.$refs['my-rule'].show()
      } else if (data === ' จีน (บ่าย)') {
        this.rule = 'จีน'
        this.$refs['my-rule'].show()
      } else if (data === 'อินเดีย') {
        this.rule = 'อินเดีย'
        this.$refs['my-rule'].show()
      } else if (data === 'รัสเซีย') {
        this.rule = 'รัสเซีย'
        this.$refs['my-rule'].show()
      } else if (data === 'ฮานอย') {
        this.rule = 'ฮานอย'
        this.$refs['my-rule'].show()
      } else if (data === 'ฮั่งเส็ง (เช้า)') {
        this.rule = 'ฮั่งเส็ง'
        this.$refs['my-rule'].show()
      } else if (data === 'ฮั่งเส็ง (บ่าย)') {
        this.rule = 'ฮั่งเส็ง'
        this.$refs['my-rule'].show()
      } else if (data === 'มาเลย์') {
        this.rule = 'มาเลย์'
        this.$refs['my-rule'].show()
      } else if (data === 'อียิปต์') {
        this.rule = 'อียิปต์'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยยี่กี') {
        this.rule = 'หวยยี่กี'
        this.$refs['my-rule'].show()
      } else if (data === 'หุ้นเกาหลี') {
        this.rule = 'หุ้นเกาหลี'
        this.$refs['my-rule'].show()
      }
    },
    async ShowAgent() {
      try {
        const { data: response } = await this.$http.get(
          'https://api.rmtlotto.com/api/member/agent/show',
        )
        if (response && response.success === true) {
          this.AgentData = response.AgentData
        }
      } catch (err) {
        console.log(err)
      }
    },
    ClearPriceBase() {
      this.confirm = false
      this.pricebase = null
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= this.countArry - 1; i++) {
        this.items[i].price = 1
      }
    },
    AddPriceBase(val) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= this.countArry - 1; i++) {
        this.items[i].price = val
      }
    },
    provide(item) {
      if (this.candidates.indexOf(item) === -1) {
        this.candidates.push(item)
      } else {
        this.candidates.splice(this.candidates.indexOf(item), 1)
      }
    },
    contains(arr, item) {
      return arr.indexOf(item) !== -1
    },
    btnStates() {
      return this.buttons.map(btn => btn.state)
    },
    // eslint-disable-next-line consistent-return
    async GetSublist() {
      const List = []
      try {
        const { data: Res } = await this.$http.get('https://lottothaiapi.linelotto.vip/api/LottoList')
        // eslint-disable-next-line
                for (const x in Res) {
          let SubList = null
          if (Res[x].Subhead.length > 0) {
            SubList = []
            // eslint-disable-next-line
                        for (const c in Res[x].Subhead) {
              SubList.push(
                { text: Res[x].Subhead[c].name, value: Res[x].Subhead[c].SubHeadID, Data: Res[x].Subhead[c] },
              )
            }
          }
          List.push(
            {
              text: Res[x].name,
              value: Res[x].HeadID,
              SubList,
              Data: Res[x],
            },
          )
        }
        return List
      } catch (e) {
        console.log(e)
      }
    },
    groupBy(list, keyGetter) {
      const map = new Map()
      list.forEach(item => {
        const key = keyGetter(item)
        const collection = map.get(key)
        if (!collection) {
          map.set(key, [item])
        } else {
          collection.push(item)
        }
      })
      return map
    },
    ShowGroupList(Key) {
      if (this.items.length > 0) {
        const grouped = this.groupBy(this.items, group => group.num_type)
        return grouped.get(Key)
      }
      return null
    },
    handleOnComplete() {
      if (this.NumBet.length === this.CalMaxLength()) {
        setTimeout(() => this.CompleteNumBet(), 100)
      }
    },
    Betting(val) {
      if (this.NumBet.length < this.CalMaxLength()) {
        this.NumBet += val
      }
      if (this.NumBet.length === this.CalMaxLength()) {
        setTimeout(() => this.CompleteNumBet(), 200)
        // console.log(this.items)
      }
    },
    showAlertHide() {
      this.$refs['modal-alert'].hide()
    },
    showAlert(CloseBet) {
      this.CloseBetModal = CloseBet
      this.$refs['modal-alert'].show()
    },
    showModal(data) {
      // console.log(data)
      this.Slip.SumAmount = data.Bet.reduce(
        (acc, ele) => acc + Number(ele.amount),
        0,
      )
      this.Slip.date = momenttz(data.Bet[0].created_at).format('DD/MM/YYYY')
      this.Slip.time = momenttz(data.Bet[0].created_at).format('HH:mm:ss')
      this.Slip.count = data.Bet.length
      this.Slip.listID = data.Bet[0].list_id
      this.Slip.LottoHeadName = data.Bet[0].LottoHeadName
      // console.log(this.Slip)
      this.$refs['my-modal'].show()
    },
    async SummitBet() {
      const CheckPrice = this.items.some(el => el.price === '')
      this.$refs.BetItemsRules.validate().then(async success => {
        if (success && CheckPrice === false) {
          this.CheckSubmit = false
          if (
            this.SumAmount > this.userbalance
          ) {
            this.SwalMes(
              'error',
              'จำนวนเงินไม่เพียงพอ !!',
              'ยอดเงินของเมมเบอร์ไม่เพียงพอกรุณาฝากเงินก่อนค่ะ',
            )
            // this.items.splice(this.items.length - 1, 1)
          } else {
            this.show = true
            const Obj = {
              SumAmount: this.SumAmount,
              discount: this.discount,
              UserData: null,
              remark: this.remark,
              OBJ: this.items,
            }
            // console.log(Obj)
            try {
              const { data: response } = await this.$http.post(
                'https://api.rmtlotto.com/api/seamless/deduct',
                Obj,
              )
              if (response) {
                this.ShowWallet()
                this.ShowTitle()
                this.GetMaxMin()
                this.GetRate(this.Title.RoundID)
                this.show = false
                this.items = []
                this.CheckSubmit = true
                this.confirm = false
                this.countArry = 0
                this.Slip.SumAmount = null
                this.Slip.date = null
                this.Slip.time = null
                this.Slip.listID = null
                this.Slip.count = null
                this.Slip.LottoHeadName = null
                if (response.success === true) {
                  this.showModal(response)
                  // this.SwalMes(
                  //   'success',
                  //   'ส่งโพยเรียบร้อย',
                  //   `ยอดแทงหวยทั้งหมด ${this.Commas(
                  //     Obj.SumAmount,
                  //   )} บาท ยอดคงเหลือ ${this.Commas(
                  //     response.WalletData.balance,
                  //   )} บาท`,
                  // )
                } else {
                  this.showAlert(response.Mes)
                }
              }
            } catch (err) {
              this.SwalMes('error', 'ส่งโพยไม่สำเร็จ', err.response.data.Mes)
              // console.log(err)
              this.CheckSubmit = true
            }
          }
        }
      })
    },
    async ShowWallet() {
      try {
        const params = {
          UserToken: localStorage.getItem('UserToken'),
        }
        const { data: response } = await this.$http.get(
          'https://api.rmtlotto.com/api/seamless/wallet/show', { params },
        )
        if (response && response.success === true) {
          const Main = response.WalletData
          this.userbalance = Main.balance
          this.discount = response.discount
        } else {
          this.userbalance = 0
        }
      } catch (err) {
        console.log(err)
      }
    },
    async ShowTitle() {
      if (this.Title.LottoDetail.u3 || this.Title.LottoDetail.d3 || this.Title.LottoDetail.b3 || this.Title.LottoDetail.t3) {
        this.ShowDigit = 3
      } else if (this.Title.LottoDetail.u2 || this.Title.LottoDetail.d2 || this.Title.LottoDetail.t2) {
        this.ShowDigit = 2
      } else if (this.Title.LottoDetail.u1 || this.Title.LottoDetail.d1) {
        this.ShowDigit = 1
      }
      const params = {
        LottoHead: this.Title.LottoHead,
        LottoSubHead: this.Title.LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/member/round/ShowByHeadAndSub/Buynow',
          { params },
        )
        if (ResData.success) {
          this.statusCheck = true
          // eslint-disable-next-line no-underscore-dangle
          this.Title.RoundID = ResData.mes._id
          this.Title.CloseBet = ResData.mes.StopBetTime
          this.Title.CloseDate = ResData.mes.CloseDate
          this.Title.CloseDateName = ResData.mes.CloseDateName
          this.Title.LimitU3 = ResData.mes.LimitU3.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitD3 = ResData.mes.LimitD3.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitB3 = ResData.mes.LimitB3.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitT3 = ResData.mes.LimitT3.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitU2 = ResData.mes.LimitU2.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitD2 = ResData.mes.LimitD2.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitT2 = ResData.mes.LimitT2.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitU1 = ResData.mes.LimitU1.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitD1 = ResData.mes.LimitD1.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.CloseBetU3 = ResData.mes.CloseBetU3.map(x => x.NumLot)
          this.Title.CloseBetD3 = ResData.mes.CloseBetD3.map(x => x.NumLot)
          this.Title.CloseBetB3 = ResData.mes.CloseBetB3.map(x => x.NumLot)
          this.Title.CloseBetT3 = ResData.mes.CloseBetT3.map(x => x.NumSort)
          this.Title.CloseBetU2 = ResData.mes.CloseBetU2.map(x => x.NumLot)
          this.Title.CloseBetD2 = ResData.mes.CloseBetD2.map(x => x.NumLot)
          this.Title.CloseBetT2 = ResData.mes.CloseBetT2.map(x => x.NumSort)
          this.Title.CloseBetU1 = ResData.mes.CloseBetU1.map(x => x.NumLot)
          this.Title.CloseBetD1 = ResData.mes.CloseBetD1.map(x => x.NumLot)
        }
      } catch (e) {
        this.statusCheck = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'แจ้งเตือน',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: 'ไม่พบรอบที่เปิดรับแทง',
          },
        })
        // console.log(e)
      }
    },
    async GetMaxMin() {
      const params = {
        LottoHead: this.Title.LottoHead,
        LottoSubHead: this.Title.LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/member/rate/ShowByHeadAndSub',
          { params },
        )
        if (ResData.success) {
          this.RateAll.Three.Up.Min = ResData.mes.MinU3
          this.RateAll.Three.Up.Max = ResData.mes.MaxU3
          this.RateAll.Three.Down_f.Min = ResData.mes.MinD3
          this.RateAll.Three.Down_f.Max = ResData.mes.MaxD3
          this.RateAll.Three.Down_b.Min = ResData.mes.MinB3
          this.RateAll.Three.Down_b.Max = ResData.mes.MaxB3
          this.RateAll.Three.Tot.Min = ResData.mes.MinT3
          this.RateAll.Three.Tot.Max = ResData.mes.MaxT3
          this.RateAll.Two.Up.Min = ResData.mes.MinU2
          this.RateAll.Two.Up.Max = ResData.mes.MaxU2
          this.RateAll.Two.Down.Min = ResData.mes.MinD2
          this.RateAll.Two.Down.Max = ResData.mes.MaxD2
          this.RateAll.Two.Tot.Min = ResData.mes.MinT2
          this.RateAll.Two.Tot.Max = ResData.mes.MaxT2
          this.RateAll.One.Up.Min = ResData.mes.MinU1
          this.RateAll.One.Up.Max = ResData.mes.MaxU1
          this.RateAll.One.Down.Min = ResData.mes.MinD1
          this.RateAll.One.Down.Max = ResData.mes.MaxD1
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetRate(RoundID) {
      const params = {
        RoundID,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/member/ratebyround/showbetmore',
          { params },
        )
        if (ResData.success) {
          // console.log(ResData.mes)
          const Rate = ResData.mes.Data
          const ListBet = ResData.mes.LimitData
          this.RateAll.Three.Up.Rate = Rate.U3
          this.RateAll.Three.Up.BetLimit = Rate.BetLimitU3
          this.RateAll.Three.Up.BetList = ListBet.U3
          this.RateAll.Three.Down_f.Rate = Rate.D3
          this.RateAll.Three.Down_f.BetLimit = Rate.BetLimitD3
          this.RateAll.Three.Down_f.BetList = ListBet.D3
          this.RateAll.Three.Down_b.Rate = Rate.B3
          this.RateAll.Three.Down_b.BetLimit = Rate.BetLimitB3
          this.RateAll.Three.Down_b.BetList = ListBet.B3
          this.RateAll.Three.Tot.Rate = Rate.T3
          this.RateAll.Three.Tot.BetLimit = Rate.BetLimitT3
          this.RateAll.Three.Tot.BetList = ListBet.T3
          this.RateAll.Two.Up.Rate = Rate.U2
          this.RateAll.Two.Up.BetLimit = Rate.BetLimitU2
          this.RateAll.Two.Up.BetList = ListBet.U2
          this.RateAll.Two.Down.Rate = Rate.D2
          this.RateAll.Two.Down.BetLimit = Rate.BetLimitD2
          this.RateAll.Two.Down.BetList = ListBet.D2
          this.RateAll.Two.Tot.Rate = Rate.T2
          this.RateAll.Two.Tot.BetLimit = Rate.BetLimitT2
          this.RateAll.Two.Tot.BetList = ListBet.T2
          this.RateAll.One.Up.Rate = Rate.U1
          this.RateAll.One.Up.BetLimit = Rate.BetLimitU1
          this.RateAll.One.Up.BetList = ListBet.U1
          this.RateAll.One.Down.Rate = Rate.D1
          this.RateAll.One.Down.BetLimit = Rate.BetLimitD1
          this.RateAll.One.Down.BetList = ListBet.D1
          // this.CloseBet.CloseU3.push(
          //   ...this.CalCloseBet(ListBet.U3, Rate.BetLimitU3),
          // )
          this.CloseBet.CloseU3 = this.CalCloseBet(
            ListBet.U3,
            this.Title.CloseBetU3,
            Rate.BetLimitU3,
          )
          this.CloseBet.CloseD3 = this.CalCloseBet(
            ListBet.D3,
            this.Title.CloseBetD3,
            Rate.BetLimitD3,
          )
          this.CloseBet.CloseB3 = this.CalCloseBet(
            ListBet.B3,
            this.Title.CloseBetB3,
            Rate.BetLimitB3,
          )
          this.CloseBet.CloseT3 = this.CalCloseBet(
            ListBet.T3,
            this.Title.CloseBetT3,
            Rate.BetLimitT3,
          )
          this.CloseBet.CloseU2 = this.CalCloseBet(
            ListBet.U2,
            this.Title.CloseBetU2,
            Rate.BetLimitU2,
          )
          this.CloseBet.CloseD2 = this.CalCloseBet(
            ListBet.D2,
            this.Title.CloseBetD2,
            Rate.BetLimitD2,
          )
          this.CloseBet.CloseT2 = this.CalCloseBet(
            ListBet.T2,
            this.Title.CloseBetT2,
            Rate.BetLimitT2,
          )
          this.CloseBet.CloseU1 = this.CalCloseBet(
            ListBet.U1,
            this.Title.CloseBetU1,
            Rate.BetLimitU1,
          )
          this.CloseBet.CloseD1 = this.CalCloseBet(
            ListBet.D1,
            this.Title.CloseBetD1,
            Rate.BetLimitD1,
          )

          this.Title.LimitU3 = this.CalLimit(
            this.Title.LimitU3,
            this.CloseBet.CloseU3,
          )
          this.Title.LimitD3 = this.CalLimit(
            this.Title.LimitD3,
            this.CloseBet.CloseD3,
          )
          this.Title.LimitB3 = this.CalLimit(
            this.Title.LimitB3,
            this.CloseBet.CloseB3,
          )
          this.Title.LimitT3 = this.CalLimit(
            this.Title.LimitT3,
            this.CloseBet.CloseT3,
          )
          this.Title.LimitU2 = this.CalLimit(
            this.Title.LimitU2,
            this.CloseBet.CloseU2,
          )
          this.Title.LimitD2 = this.CalLimit(
            this.Title.LimitD2,
            this.CloseBet.CloseD2,
          )
          this.Title.LimitT2 = this.CalLimit(
            this.Title.LimitT2,
            this.CloseBet.CloseT2,
          )
          this.Title.LimitU1 = this.CalLimit(
            this.Title.LimitU1,
            this.CloseBet.CloseU1,
          )
          this.Title.LimitD1 = this.CalLimit(
            this.Title.LimitD1,
            this.CloseBet.CloseD1,
          )
        }
      } catch (e) {
        console.log(e)
      }
    },
    CompleteNumBet() {
      if (this.ShowDigit === 3) {
        this.PushDigit3()
      } else if (this.ShowDigit === 2) {
        this.PushDigit2()
      } else if (this.ShowDigit === 1) {
        this.PushDigit1()
      }
    },
    async PushDigit3() {
      const Swap = this.BetType.indexOf('กลับตัวเลข')
      const UP3 = this.BetType.indexOf('3ตัวบน')
      const DOWN_F3 = this.BetType.indexOf('3ตัวหน้า')
      const DOWN_B3 = this.BetType.indexOf('3ตัวท้าย')
      const TOT = this.BetType.indexOf('3ตัวโต๊ด')
      // eslint-disable-next-line no-nested-ternary
      if (Swap >= 0) {
        if (UP3 >= 0) {
          const Switch3Arr = this.SwitchNum3(this.NumBet)
          await Switch3Arr.forEach(ele => {
            this.PushItems(ele, '3ตัวบน', 3, 'UP', this.AmountBet)
          })
        }
        if (DOWN_F3 >= 0) {
          const Switch3Arr = this.SwitchNum3(this.NumBet)
          await Switch3Arr.forEach(ele => {
            this.PushItems(ele, '3ตัวหน้า', 3, 'DOWN_F', this.AmountBet)
          })
        }
        if (DOWN_B3 >= 0) {
          const Switch3Arr = this.SwitchNum3(this.NumBet)
          await Switch3Arr.forEach(ele => {
            this.PushItems(ele, '3ตัวท้าย', 3, 'DOWN_B', this.AmountBet)
          })
        }
      } else if (TOT >= 0 || UP3 >= 0 || DOWN_F3 >= 0 || DOWN_B3 >= 0) {
        if (TOT >= 0) {
          await this.PushItems(
            this.NumBet,
            '3ตัวโต๊ด',
            3,
            'TOT',
            this.AmountBet,
          )
        }
        if (UP3 >= 0) {
          await this.PushItems(this.NumBet, '3ตัวบน', 3, 'UP', this.AmountBet)
        }
        if (DOWN_F3 >= 0) {
          await this.PushItems(
            this.NumBet,
            '3ตัวหน้า',
            3,
            'DOWN_F',
            this.AmountBet,
          )
        }
        if (DOWN_B3 >= 0) {
          await this.PushItems(
            this.NumBet,
            '3ตัวท้าย',
            3,
            'DOWN_B',
            this.AmountBet,
          )
        }
      }
      this.ClearAfterBet()
      this.PushHistory(this.items)
    },
    async PushDigit2() {
      const Up = this.BetType.indexOf('2ตัวบน') >= 0
      const Down = this.BetType.indexOf('2ตัวล่าง') >= 0
      const Tot = this.BetType.indexOf('2ตัวโต๊ด(บน)') >= 0
      if (this.BetType.indexOf('19ประตู') >= 0) {
        const Res = this.Swipe19(this.NumBet)
        if (Up) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวบน', 2, 'UP', this.AmountBet)
          })
        }
        if (Down) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', this.AmountBet)
          })
        }
      } else if (this.BetType.indexOf('รูดหน้า') >= 0) {
        const Res = this.SwipeFront(this.NumBet)
        if (Up) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวบน', 2, 'UP', this.AmountBet)
          })
        }
        if (Down) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', this.AmountBet)
          })
        }
      } else if (this.BetType.indexOf('รูดหลัง') >= 0) {
        const Res = this.SwipeBack(this.NumBet)
        if (Up) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวบน', 2, 'UP', this.AmountBet)
          })
        }
        if (Down) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', this.AmountBet)
          })
        }
      } else if (this.BetType.indexOf('กลับตัวเลข') >= 0) {
        const Res = this.SwitchNum2(this.NumBet)
        if (Up) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวบน', 2, 'UP', this.AmountBet)
          })
        }
        if (Down) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', this.AmountBet)
          })
        }
      } else {
        if (Up) {
          await this.PushItems(this.NumBet, '2ตัวบน', 2, 'UP', this.AmountBet)
        }
        if (Down) {
          await this.PushItems(this.NumBet, '2ตัวล่าง', 2, 'DOWN', this.AmountBet)
        }
        if (Tot) {
          await this.PushItems(this.NumBet, '2ตัวโต๊ด(บน)', 2, 'TOT', this.AmountBet)
        }
      }
      this.ClearAfterBet()
      this.PushHistory(this.items)
    },
    async PushDigit1() {
      const NumType = this.BetType.indexOf('วิ่งบน') >= 0 ? 'วิ่งบน' : 'วิ่งล่าง'
      const NumTypePrefix = this.BetType.indexOf('วิ่งบน') >= 0 ? 'UP' : 'DOWN'

      await this.PushItems(
        this.NumBet,
        NumType,
        1,
        NumTypePrefix,
        this.AmountBet,
      )
      this.ClearAfterBet()
      this.PushHistory(this.items)
    },
    async PushItems(NumBet, NumType, BetDigit, BetType, Price) {
      // console.log(NumBet, NumType, BetDigit, BetType, Price)
      // eslint-disable-next-line no-new
      new Promise((resolve, reject) => {
        const { Rate, RateLimit } = this.CalRate(NumBet, BetDigit, BetType)
        let NumSort = null
        let CheckDup = null
        if (BetType === 'TOT') {
          const NumArr = NumBet.split('')
          NumArr.sort()
          NumSort = NumArr.join('')
          CheckDup = this.items.findIndex(
            obj => obj.NumSort === NumSort
                            && obj.bet_digit === BetDigit
                            && obj.bet_type === BetType,
          )
        } else {
          CheckDup = this.items.findIndex(
            obj => obj.num_lot === NumBet
                            && obj.bet_digit === BetDigit
                            && obj.bet_type === BetType,
          )
        }

        if (CheckDup >= 0) {
          const CheckLimit = this.CheckBetLimit(
            NumBet,
            BetDigit,
            BetType,
            Number(Price) + Number(this.items[CheckDup].price),
          )
          if (CheckLimit.status === true) {
            this.items[CheckDup].price = Number(Price) + Number(this.items[CheckDup].price)
            resolve()
          } else if (CheckLimit.amount <= 0) {
            this.SwalMes('error', 'ปิดรับแทง', `${NumType} หมายเลข ${NumBet}`)
            reject()
          } else {
            this.SwalMes(
              'error',
              'ไม่สำเร็จ !',
              `${NumType} หมายเลข ${NumBet} รับแทงไม่เกิน ${this.Commas(
                CheckLimit.amount,
              )} บาท`,
            )
            reject()
          }
        } else {
          const CheckLimit = this.CheckBetLimit(
            NumBet,
            BetDigit,
            BetType,
            Number(Price),
          )
          if (CheckLimit.status === true) {
            if (RateLimit) {
              this.SwalMes('info', 'หมายเลขจ่ายตาม %', `${NumType} หมายเลข ${NumBet} จ่าย ${Rate} บาท`)
            }
            const ObjItems = {
              num_lot: NumBet,
              num_type: NumType,
              bet_digit: BetDigit, // 1, 2 ,3
              bet_type: BetType, // TOT , UP , DOWN
              price: Price,
              round_id: this.Title.RoundID,
              LottoHead: this.Title.LottoHead,
              LottoSubHead: this.Title.LottoSubHead
                ? this.Title.LottoSubHead
                : null,
              win_rate: Rate,
              NumSort,
              RateLimit,
            }
            this.items.push(ObjItems)
            resolve()
          } else if (CheckLimit.amount === 0) {
            this.SwalMes('error', 'ปิดรับแทง', `${NumType} หมายเลข ${NumBet}`)
            reject()
          } else {
            this.SwalMes(
              'error',
              'ไม่สำเร็จ !',
              `${NumType} หมายเลข ${NumBet} รับแทงไม่เกิน ${this.Commas(
                CheckLimit.amount,
              )} บาท`,
            )
            reject()
          }
        }
      })
    },
    async PushHistory(Val) {
      this.countArry = Val.length
      const St = JSON.stringify(Val)
      this.Historyitems.push(JSON.parse(St))
    },
    CalCloseBet(Arr, CloseList, BetLimit) {
      // console.log(Arr, CloseList, BetLimit)
      let arrArr = Arr.filter(ele => ele.totalAmount >= BetLimit)
      // eslint-disable-next-line no-underscore-dangle
      arrArr = arrArr.map(x => x._id)
      if (CloseList.length > 0) {
        arrArr.push(...CloseList)
      }
      return arrArr.sort()
    },
    CalRate(NumBet, BetDigit, BetType) {
      let Rate = 0
      let IndexLimit = null
      let RateLimit = false
      if (BetDigit === 1) {
        if (BetType === 'UP') {
          IndexLimit = this.Title.LimitU1.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitU1[IndexLimit].Rate
          } else {
            Rate = this.RateAll.One.Up.Rate
          }
        } else if (BetType === 'DOWN') {
          IndexLimit = this.Title.LimitD1.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitD1[IndexLimit].Rate
          } else {
            Rate = this.RateAll.One.Down.Rate
          }
        }
      } else if (BetDigit === 2) {
        if (BetType === 'UP') {
          IndexLimit = this.Title.LimitU2.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitU2[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Two.Up.Rate
          }
        } else if (BetType === 'DOWN') {
          IndexLimit = this.Title.LimitD2.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitD2[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Two.Down.Rate
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          IndexLimit = this.Title.LimitT3.findIndex(
            ({ NumSort }) => NumSort === SortBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitT2[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Two.Tot.Rate
          }
        }
      } else if (BetDigit === 3) {
        if (BetType === 'UP') {
          IndexLimit = this.Title.LimitU3.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitU3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Up.Rate
          }
        } else if (BetType === 'DOWN_F') {
          IndexLimit = this.Title.LimitD3.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitD3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Down_f.Rate
          }
        } else if (BetType === 'DOWN_B') {
          IndexLimit = this.Title.LimitB3.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitB3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Down_b.Rate
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          IndexLimit = this.Title.LimitT3.findIndex(
            ({ NumSort }) => NumSort === SortBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitT3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Tot.Rate
          }
        }
      }
      if (IndexLimit >= 0) {
        RateLimit = true
      }
      return { Rate, RateLimit }
    },
    CalLimit(Limit, CloseBet) {
      return Limit.filter(val => !CloseBet.includes(val.NumLot))
    },
    SelectDigit(Val) {
      this.NumBet = ''
      this.AmountBet = 1
      this.BetType = []
      this.CheckBetType = true
      this.maxlength = Val
      this.ShowDigit = Val
      this.BugOtp = false
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.BugOtp = true), 10)
    },
    ClearAfterBet() {
      this.NumBet = ''
      // this.AmountBet = 1
      // this.BugOtp = false
      // // eslint-disable-next-line no-return-assign
      // setTimeout(() => (this.BugOtp = true), 10)
    },
    SelectBetType3(Val) {
      this.SelectUnDup(this.BetType, Val)
      if (Val === 'กลับตัวเลข') {
        this.CheckRemove(this.BetType, '3ตัวโต๊ด')
      }
      if (Val === '3ตัวโต๊ด') {
        this.CheckRemove(this.BetType, 'กลับตัวเลข')
      }
      this.CheckBetDisable(this.BetType, '3ตัวบน', '3ตัวโต๊ด', '3ตัวหน้า', '3ตัวท้าย')
    },
    async SelectBetType2(Val) {
      // eslint-disable-next-line no-nested-ternary
      const NumType = this.BetType.indexOf('2ตัวบน') >= 0
        ? '2ตัวบน'
        : this.BetType.indexOf('2ตัวล่าง') >= 0
          ? '2ตัวล่าง'
          : '2ตัวโต๊ด(บน)'
      // eslint-disable-next-line no-nested-ternary
      const NumTypePrefix = this.BetType.indexOf('2ตัวบน') >= 0
        ? 'UP'
        : this.BetType.indexOf('2ตัวล่าง') >= 0
          ? 'DOWN'
          : 'TOT'
      if (Val === 'สองตัวคี่') {
        const Res = this.SwipeOdd()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
        this.PushHistory(this.items)
      } else if (Val === 'สองตัวคู่') {
        const Res = this.SwipeEven()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
        this.PushHistory(this.items)
      } else if (Val === 'สองตัวสูง') {
        const Res = this.SwipeHigh()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
        this.PushHistory(this.items)
      } else if (Val === 'สองตัวต่ำ') {
        const Res = this.SwipeLow()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
        this.PushHistory(this.items)
      } else {
        this.SelectUnDup(this.BetType, Val)
        if (Val === '2ตัวบน') {
          this.maxlength = 2
          // this.CheckRemove(this.BetType, '2ตัวล่าง')
          this.CheckRemove(this.BetType, '2ตัวโต๊ด(บน)')
        } else if (Val === '2ตัวล่าง') {
          this.maxlength = 2
          // this.CheckRemove(this.BetType, '2ตัวบน')
          // this.CheckRemove(this.BetType, '2ตัวโต๊ด(บน)')
        } else if (Val === '2ตัวโต๊ด(บน)') {
          this.maxlength = 2
          this.CheckRemove(this.BetType, '2ตัวบน')
          this.CheckRemove(this.BetType, '2ตัวล่าง')
        } else if (Val === '19ประตู') {
          this.maxlength = 1
          this.CheckRemove(this.BetType, 'รูดหน้า')
          this.CheckRemove(this.BetType, 'รูดหลัง')
          this.CheckRemove(this.BetType, 'กลับตัวเลข')
          this.CheckRemove(this.BetType, 'สองตัวคี่')
          this.CheckRemove(this.BetType, 'สองตัวคู่')
          this.CheckRemove(this.BetType, 'สองตัวสูง')
          this.CheckRemove(this.BetType, 'สองตัวต่ำ')
        } else if (Val === 'รูดหน้า') {
          this.maxlength = 1
          this.CheckRemove(this.BetType, '19ประตู')
          this.CheckRemove(this.BetType, 'รูดหลัง')
          this.CheckRemove(this.BetType, 'กลับตัวเลข')
          this.CheckRemove(this.BetType, 'สองตัวคี่')
          this.CheckRemove(this.BetType, 'สองตัวคู่')
          this.CheckRemove(this.BetType, 'สองตัวสูง')
          this.CheckRemove(this.BetType, 'สองตัวต่ำ')
        } else if (Val === 'รูดหลัง') {
          this.maxlength = 1
          this.CheckRemove(this.BetType, '19ประตู')
          this.CheckRemove(this.BetType, 'รูดหน้า')
          this.CheckRemove(this.BetType, 'กลับตัวเลข')
          this.CheckRemove(this.BetType, 'สองตัวคี่')
          this.CheckRemove(this.BetType, 'สองตัวคู่')
          this.CheckRemove(this.BetType, 'สองตัวสูง')
          this.CheckRemove(this.BetType, 'สองตัวต่ำ')
        } else if (Val === 'กลับตัวเลข') {
          this.CheckRemove(this.BetType, '19ประตู')
          this.CheckRemove(this.BetType, 'รูดหน้า')
          this.CheckRemove(this.BetType, 'รูดหลัง')
          this.CheckRemove(this.BetType, 'สองตัวคี่')
          this.CheckRemove(this.BetType, 'สองตัวคู่')
          this.CheckRemove(this.BetType, 'สองตัวสูง')
          this.CheckRemove(this.BetType, 'สองตัวต่ำ')
        }
        this.CheckBetDisable(this.BetType, '2ตัวบน', '2ตัวล่าง', '2ตัวโต๊ด(บน)')
      }
    },
    SelectBetType1(Val) {
      this.BetType = []
      this.SelectUnDup(this.BetType, Val)
      this.CheckBetDisable(this.BetType, 'วิ่งบน', 'วิ่งล่าง')
    },
    CheckRemove(Arr, Val) {
      const Index = Arr.indexOf(Val)
      if (Index >= 0) {
        Arr.splice(Index, 1)
      }
    },
    SelectUnDup(Arr, Val) {
      const Index = Arr.indexOf(Val)
      if (Index < 0) {
        Arr.push(Val)
        const { Min, Max } = this.CheckAmount(Val)
        if (Max !== 0) {
          this.MinAmount = Min
          this.MaxAmount = Max
        }
      } else {
        Arr.splice(Index, 1)
      }
    },
    CheckBetDisable(Arr, Val1, Val2, Val3, Val4) {
      const Index1 = Arr.indexOf(Val1)
      const Index2 = Arr.indexOf(Val2)
      const Index3 = Arr.indexOf(Val3)
      const Index4 = Arr.indexOf(Val4)
      if (Index1 >= 0 || Index2 >= 0 || Index3 >= 0 || Index4 >= 0) {
        this.CheckBetType = false
      } else {
        this.CheckBetType = true
      }
    },
    TestPrice(Price, Min, Max) {
      if (Price < Min || Price > Max) {
        return true
      }
      return false
    },
    CheckAmount(Val, Price, Index, NumBet = null) {
      const MinMax = { Min: 0, Max: 0 }
      let BetDigit = 3
      let BetType = 'UP'
      if (Val === '3ตัวบน') {
        MinMax.Min = this.RateAll.Three.Up.Min
        MinMax.Max = this.RateAll.Three.Up.Max
      } else if (Val === '3ตัวหน้า') {
        MinMax.Min = this.RateAll.Three.Down_f.Min
        MinMax.Max = this.RateAll.Three.Down_f.Max
        BetType = 'DOWN_F'
      } else if (Val === '3ตัวท้าย') {
        MinMax.Min = this.RateAll.Three.Down_b.Min
        MinMax.Max = this.RateAll.Three.Down_b.Max
        BetType = 'DOWN_B'
      } else if (Val === '3ตัวโต๊ด') {
        MinMax.Min = this.RateAll.Three.Tot.Min
        MinMax.Max = this.RateAll.Three.Tot.Max
        BetType = 'TOT'
      } else if (Val === '2ตัวบน') {
        MinMax.Min = this.RateAll.Two.Up.Min
        MinMax.Max = this.RateAll.Two.Up.Max
        BetDigit = 2
        BetType = 'UP'
      } else if (Val === '2ตัวล่าง') {
        MinMax.Min = this.RateAll.Two.Down.Min
        MinMax.Max = this.RateAll.Two.Down.Max
        BetDigit = 2
        BetType = 'DOWN'
      } else if (Val === '2ตัวโต๊ด(บน)') {
        MinMax.Min = this.RateAll.Two.Tot.Min
        MinMax.Max = this.RateAll.Two.Tot.Max
        BetDigit = 2
        BetType = 'TOT'
      } else if (Val === 'วิ่งบน') {
        MinMax.Min = this.RateAll.One.Up.Min
        MinMax.Max = this.RateAll.One.Up.Max
        BetDigit = 1
        BetType = 'UP'
      } else if (Val === 'วิ่งล่าง') {
        MinMax.Min = this.RateAll.One.Down.Min
        MinMax.Max = this.RateAll.One.Down.Max
        BetDigit = 1
        BetType = 'DOWN'
      }
      if (Price) {
        if (Price < MinMax.Min) {
          this.items[Index].price = MinMax.Min
        } else if (Price > MinMax.Max) {
          this.items[Index].price = MinMax.Max
        }
        const CheckLimit = this.CheckBetLimit(
          NumBet,
          BetDigit,
          BetType,
          Number(Price),
        )
        if (CheckLimit.status === false) {
          this.items[Index].price = CheckLimit.amount > 0 ? CheckLimit.amount : 0
        }
      }
      return MinMax
    },
    CheckBetLimit(NumBet, BetDigit, BetType, Price) {
      let TotalAmount = Price
      let SumAmount = 0
      if (BetDigit === 1) {
        if (BetType === 'UP') {
          const CloseIn = this.CloseBet.CloseU1.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.One.Up.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.One.Up.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.One.Up.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN') {
          const CloseIn = this.CloseBet.CloseD1.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.One.Down.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.One.Down.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.One.Down.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        }
      } else if (BetDigit === 2) {
        if (BetType === 'UP') {
          const CloseIn = this.CloseBet.CloseU2.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Two.Up.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Two.Up.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Two.Up.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN') {
          const CloseIn = this.CloseBet.CloseD2.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Two.Down.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Two.Down.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Two.Down.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          const CloseIn = this.CloseBet.CloseT2.indexOf(SortBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Two.Tot.BetList.findIndex(
            ({ _id }) => _id === SortBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Two.Tot.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Two.Tot.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        }
      } else if (BetDigit === 3) {
        if (BetType === 'UP') {
          const CloseIn = this.CloseBet.CloseU3.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Up.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Up.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Up.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN_F') {
          const CloseIn = this.CloseBet.CloseD3.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Down_f.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Down_f.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Down_f.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN_B') {
          const CloseIn = this.CloseBet.CloseB3.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Down_b.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Down_b.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Down_b.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          const CloseIn = this.CloseBet.CloseT3.indexOf(SortBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Tot.BetList.findIndex(
            ({ _id }) => _id === SortBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Tot.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Tot.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        }
      }
      return { status: true }
    },
    // 3Digit
    SwitchNum3(textnum) {
      const ReArr = []
      const num1 = textnum.substr(0, 1)
      const num2 = textnum.substr(1, 1)
      const num3 = textnum.substr(2, 1)
      if (num1 === num2 || num1 === num3 || num2 === num3) {
        if (num1 === num2) {
          ReArr.push(num1 + num1 + num3, num1 + num3 + num1, num3 + num1 + num1)
        } else if (num1 === num3) {
          ReArr.push(num1 + num2 + num1, num1 + num1 + num2, num2 + num1 + num1)
        } else {
          ReArr.push(num1 + num2 + num2, num2 + num2 + num1, num2 + num1 + num2)
        }
      } else {
        ReArr.push(
          num1 + num2 + num3,
          num1 + num3 + num2,
          num2 + num1 + num3,
          num2 + num3 + num1,
          num3 + num1 + num2,
          num3 + num2 + num1,
        )
      }
      return ReArr
    },
    // 2Digit
    SwitchNum2(textnum) {
      const ReArr = []
      const Val = String(textnum)
      const num1 = Val.substr(0, 1)
      const num2 = Val.substr(1, 1)
      if (num1 === num2) {
        ReArr.push(num1 + num2)
      } else {
        ReArr.push(num1 + num2, num2 + num1)
      }
      return ReArr
    },
    SwipeFront(Val) {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        const R1 = String(Val) + i
        ReArr.push(R1)
      }
      return ReArr
    },
    SwipeBack(Val) {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        const R1 = i + String(Val)
        ReArr.push(R1)
      }
      return ReArr
    },
    Swipe19(Val) {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        const R1 = i + String(Val)
        ReArr.push(R1)
      }
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        if (i === Number(Val)) {
          // eslint-disable-next-line no-plusplus
          i++
        }
        if (i !== 10) {
          const R2 = String(Val) + i
          ReArr.push(R2)
        }
      }
      return ReArr
    },
    SwipeLow() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 50; i++) {
        if (i < 10) {
          ReArr.push(`0${i}`)
        } else {
          ReArr.push(String(i))
        }
      }
      return ReArr
    },
    SwipeHigh() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 50; i < 100; i++) {
        if (i < 10) {
          ReArr.push(`0${i}`)
        } else {
          ReArr.push(String(i))
        }
      }
      return ReArr
    },
    SwipeOdd() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 100; i++) {
        if (i % 2) {
          if (i < 10) {
            ReArr.push(`0${i}`)
          } else {
            ReArr.push(String(i))
          }
        }
      }
      return ReArr
    },
    SwipeEven() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 100; i++) {
        if (i % 2 !== 1) {
          if (i < 10) {
            ReArr.push(`0${i}`)
          } else {
            ReArr.push(String(i))
          }
        }
      }
      return ReArr
    },
    Undo() {
      this.items = []
      this.Historyitems = []
      this.countArry = 0
      // if (this.Historyitems.length > 1) {
      //   this.Historyitems.pop()
      //   this.items = this.Historyitems[this.Historyitems.length - 1]
      // } else {
      //   this.items = []
      //   this.Historyitems = []
      // }
    },
    CalMaxLength() {
      const CheckLengthType = this.BetType.findIndex(
        obj => obj === '19ประตู' || obj === 'รูดหน้า' || obj === 'รูดหลัง',
      )
      if (CheckLengthType >= 0) {
        this.maxlength = 1
      } else {
        this.maxlength = this.ShowDigit
      }
      return this.maxlength
    },
    CalSumAmount() {
      const SumAmount = this.items.reduce(
        (acc, ele) => acc + Number(ele.price),
        0,
      )
      if (SumAmount > this.userbalance) {
        if (this.CheckSubmit) {
          this.SwalMes(
            'error',
            'จำนวนเงินไม่ถูกต้อง !',
            'ยอดเงินของคุณไม่เพียงพอกรุณาลองใหม่อีกครั้งค่ะ',
          )
          this.CheckSubmit = !this.CheckSubmit
        }
      } else {
        this.CheckSubmit = true
      }
      if (this.discount_member > 0) {
        this.SumAmount = SumAmount - ((this.discount_member * SumAmount) / 100)
        return this.SumAmount
      }
      this.SumAmount = SumAmount
      return this.SumAmount
    },
    // eslint-disable-next-line consistent-return
    CalDiffTime(Val) {
      const DateRound = momenttz(Val).tz('Asia/Bangkok')
      const DateNow = momenttz().tz('Asia/Bangkok')
      const Seconds = DateRound.diff(DateNow, 'seconds')

      const Day = Math.floor(Seconds / 86400)
      const Hours = Math.floor((Seconds % 86400) / 3600)
      const Minutes = Math.floor((Seconds % 3600) / 60)
      const numseconds = Math.floor((Seconds % 3600) % 60)

      if (Day >= 0) {
        return `${Day} วัน ${Hours < 10 ? `0${Hours}` : Hours} : ${Minutes < 10 ? `0${Minutes}` : Minutes
        } : ${numseconds < 10 ? `0${numseconds}` : numseconds}`
      }
      clearInterval(this.Interval)
      this.$router.push({ name: 'lottery' })
    },
    Commas(x) {
      if (x) {
        if (Math.round(x) !== x && x > 0) {
          const Num = Number(x)
          const y = Num.toFixed(2)
          return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
    SwalMes(Type, Title, Html) {
      this.$swal({
        icon: Type,
        title: Title,
        html: Html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    // Modal
    // showModal() {
    //   this.$refs['my-modal'].show()
    // },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
  },
}
</script>
<style scoped>
.button-lot {
  backface-visibility: hidden;
  background: rgb(2,52,69);
background: linear-gradient(0deg, rgba(2,52,69,1) 2%, rgba(1,178,149,1) 100%);
  margin-left: 5px;
  margin-right: 5px;
  border: 0;
  width: 100%;
  text-align: center;
  border-radius: .375rem;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: -.01em;
  line-height: 1.3;
  padding: 1rem 1.25rem;
  position: relative;
  text-decoration: none;
  transform: translateZ(0) scale(1);
  transition: transform .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-lot:disabled {
  color: #787878;
  cursor: auto;
}

.button-lot:not(:disabled):hover {
  transform: scale(1.05);
}

.button-lot:not(:disabled):hover:active {
  transform: scale(1.05) translateY(.125rem);
}

.button-lot:focus {
  outline: 0 solid transparent;
}

.button-lot:focus:before {
  border-width: .125rem;
  content: "";
  left: calc(-1*.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1*.375rem);
  transition: border-radius;
  user-select: none;
}

.button-lot:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}

.button-lot:not(:disabled):active {
  transform: translateY(.125rem);
}
.active {
  background: rgb(2,52,69);
background: linear-gradient(0deg, rgba(2,52,69,1) 2%, rgb(0, 119, 99) 100%);
  border: #e7bd00 3px solid;
}
label {
    font-weight: 600;
    font-size: 1.2rem;
}
.bage-num {
    background: #053519;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
}
.form-control {
    width: 100% !important;
    font-weight: 500;
    height: 45px;
    padding: 5px;
    font-size: 1.2rem;
}
.card-sum {
    background: #f0ffbc;
    color: #fff;
    padding: 2px;
    border-radius: 5px;
    box-shadow: #aaaaaabd 0px 0px 5px;
}
.bg-aqua {
    background: #f0ffbc !important;
    color: #000 !important;
    font-weight: 600 !important;
    font-size: 1rem;
}
</style>
